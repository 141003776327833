.custom-step-from {
  .custom-input {
    border-color: #d8d7d7;
    border-radius: 3px;
    padding: 10px;
    // resize: none;
    @media only screen and (max-width: 790px) {
      padding: 8px;
    }
    &::placeholder {
      font-size: 16px;
    }
  }
  .ant-form-item {
    width: 100% !important;
  }
  :where(.css-dev-only-do-not-override-qgg3xn).ant-checkbox-checked
    .ant-checkbox-inner {
    background: #fff;
    border-color: #dddddd;
    border-radius: 0 !important;
  }
  :where(.css-dev-only-do-not-override-qgg3xn) .ant-checkbox-inner {
    background: #fff;
    border-color: #dddddd;
    border-radius: 0 !important;
  }
  :where(.css-dev-only-do-not-override-qgg3xn).ant-checkbox-checked:not(
      .ant-checkbox-disabled
    ):hover
    .ant-checkbox-inner,
  :where(.css-dev-only-do-not-override-qgg3xn).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    border-color: #dddddd !important;
    background: transparent !important;
  }
  :where(.css-dev-only-do-not-override-qgg3xn).ant-checkbox
    .ant-checkbox-inner:after {
    border-color: #004078;
  }
  .custom-form-btn {
    background: #fff;
    color: #151515;
    border-color: #151515;
    border-radius: 3px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Roboto';
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 40px;
    &.primary {
      background: #004078;
      color: #fff;

      &:hover {
        border-color: #004078 !important;
        color: #fff !important;
        background: #004078d7;
      }
    }
    &:disabled {
      background: transparent;
      color: #151515;
      opacity: 0.2;
    }
    &:hover {
      border-color: #151515 !important;
      color: #151515 !important;
      background: #e2f5ff;
    }
  }
  :where(.css-dev-only-do-not-override-qgg3xn).ant-switch.ant-switch-checked,
  :where(
      .css-dev-only-do-not-override-qgg3xn
    ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #8cb83c !important;
  }
}
:where(.css-qgg3xn).ant-switch.ant-switch-checked {
  background: #8cb83c !important;
}

.ant-collapse-expand-icon {
  margin-left: 3%; // Push the expand icon to the right
  display: flex;
  align-items: center;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-collapse {
  border-radius: 0;
  // background-color: rgb(239 250 255);
}
